import Authentication from "components/Authentication";
import MainLayout from "components/MainLayout";
import Metadata from "components/Metadata";
import React from "react";

export default function LoginPage() {
  return (
    <MainLayout>
      <Metadata
        title="Log In | Pipers For Hire"
        description="Log in to the fastest growing site for bagpipers looking to book more gigs. Get started for free or explore our membership plans for more features."
        url="https://pipersforhire.com/login"
      />
      <Authentication />
    </MainLayout>
  );
}
