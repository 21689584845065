interface Props {
  children: React.ReactNode;
}

export default function MainLayout(props: Props) {
  return (
    <div
      className={`bg-theme-white mx-auto flex w-full max-w-screen-lg flex-1 flex-col px-3 py-10 sm:px-6 sm:py-20`}
    >
      {props.children}
    </div>
  );
}
